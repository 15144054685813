import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppContextState, Mandator, SisterSociety, TopToasterState } from "../types";
import { getSisterSocieties, getMandators } from "../api/appContextApi";
import { getConfiguration } from "../api/configurationAPI";
import { RootState } from "../store/store";
import { getRepertoires } from "../api/commonsApi";

export const initialState: AppContextState = {
  sisterSocities: null,
  switchAccount: false,
  loading: false,
  responseCode: "OK",
  expirationToken: false,
  selectedSisterSociety: null,
  mandator: null,
  mandatorStatus: 0,
  mandatorStartDate: "",
  profile: null,
  repertoires: {
    status: -1,
    data: [],
  },
  selectedRepertoire: "all-repertoires",
  breadcrumb: {
    title: "",
    buttonAction: "",
    buttonLabel: "",
    showHome: true,
    isVisible: true,
  },
  TopToasterState: {
    status: false,
    messageTranslationKey: "",
    nature: "error",
  },
  configuration: {},
};

export const fetchRepertoiresAsync = createAsyncThunk(
  "appContext/repertoires/fetch",
  async (params: { societyId: string }, { rejectWithValue }) => {
    try {
      return await getRepertoires(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSisterSocietiesAsync = createAsyncThunk(
  "get_sister_societies",
  async (_, { rejectWithValue }) => {
    try {
      return await getSisterSocieties();
    } catch (err) {
      console.error("ERROR ", err);
      return rejectWithValue(err);
    }
  }
);

export const getMandatorsAsync = createAsyncThunk(
  "get_mondators",
  async (params: { societyId: string }, { getState, rejectWithValue }) => {
    try {
      return await getMandators(params);
    } catch (err) {
      console.error("ERROR getMandators ", err);
      return rejectWithValue(err);
    }
  }
);

export const getConfigurationsAsync = createAsyncThunk(
  "configurations/load",
  async (_, { rejectWithValue }) => {
    try {
      return await getConfiguration();
    } catch (err) {
      console.error("ERROR ", err);
      return rejectWithValue(err);
    }
  }
);

export const selectedSociety = (state: RootState) =>
  <SisterSociety | null>state.appContext.selectedSisterSociety;
export const profile = (state: RootState) => <any>state.appContext.profile;
export const selectedMandator = (state: RootState) => <Mandator | null>state.appContext.mandator;
export const loadingSociety = (state: RootState) => <boolean>state.appContext.loading;

export const resetContext = createAction("app/reset-context");

export const appContextSlice = createSlice({
  name: "appContext",
  initialState,
  reducers: {
    setSwitchAccount: (state, action) => {
      const selectedSisterSociety = action.payload.selectedSisterSociety;
      if (selectedSisterSociety) {
        return { ...state, switchAccount: action.payload.switchAccount, selectedSisterSociety };
      }
      return { ...state, switchAccount: action.payload.switchAccount };
    },
    setProfile: (state, action) => ({ ...state, profile: action.payload.profile }),
    expirationToken: (state) => ({ ...state, expirationToken: true }),
    setBreadcrumb: (state, action) => {
      const { title, buttonAction, buttonLabel, showHome, parent, featKey, isVisible } =
        action.payload;
      return {
        ...state,
        breadcrumb: {
          ...state.breadcrumb,
          title,
          buttonAction,
          buttonLabel,
          showHome,
          parent,
          featKey,
          isVisible,
        },
      };
    },
    setToasterState: (state, action) => {
      return {
        ...state,
        TopToasterState: {
          status: action.payload.status,
          messageTranslationKey: action.payload?.messageTranslationKey,
          nature: action.payload?.kind,
        },
      };
    },
    setSelectedRepertoire: (state: AppContextState, action) => {
      state.selectedRepertoire = action.payload;
    },
    resetSelectedRepertoires: (state: AppContextState) => {
      state.selectedRepertoire = "all-repertoires";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRepertoiresAsync.pending, (state: AppContextState) => {
        state.repertoires.status = -1;
      })
      .addCase(fetchRepertoiresAsync.fulfilled, (state: AppContextState, action: any) => {
        return {
          ...state,
          selectedRepertoire: "all-repertoires",
          repertoires: {
            status: 200,
            data: action.payload?.data?.repertoires ?? [],
          },
        };
      })
      .addCase(fetchRepertoiresAsync.rejected, (state: AppContextState, action: any) => {
        return {
          ...state,
          repertoires: {
            ...state.repertoires,
            data: [],
          },
        };
      })
      .addCase(getSisterSocietiesAsync.pending, (state) => {
        return {
          ...state,
          loading: true,
          responseCode: "idle",
        };
      })
      .addCase(getSisterSocietiesAsync.fulfilled, (state, action) => {
        const isMultiAccount = action.payload.identities.length
          ? action.payload.identities.length > 1
          : false;
        return {
          ...state,
          responseCode: "200",
          loading: false,
          sisterSocities: action.payload,
          switchAccount: isMultiAccount,
          selectedSisterSociety:
            !isMultiAccount && action.payload.identities.length
              ? action.payload.identities[0]
              : null,
        };
      })
      .addCase(getSisterSocietiesAsync.rejected, (state, action) => {
        console.log(
          "-> rejected",
          state,
          "message",
          //action?.payload?.message,
          "action",
          action
        );

        return {
          ...state,
          //responseCode: action.payload?.code,
          loading: false,
          sisterSocities: null,
        };
      })
      .addCase(getMandatorsAsync.pending, (state) => {
        return {
          ...state,
          loading: true,
          mandatorStatus: 0,
          responseCode: "idle",
        };
      })
      .addCase(getMandatorsAsync.fulfilled, (state, action) => {
        return {
          ...state,
          responseCode: "200",
          mandatorStatus: 2,
          mandatorStartDate: action.payload?.identitiesMandators.startDate
            ? action.payload?.identitiesMandators.startDate
            : "",
          loading: false,
          mandator: action.payload?.identitiesMandators.mandatorId
            ? action.payload?.identitiesMandators.mandatorId
            : null,
        };
      })
      .addCase(getMandatorsAsync.rejected, (state, action) => {
        console.log(
          "-> rejected",
          state,
          "message",
          //action?.payload?.message,
          "action",
          action
        );

        return {
          ...state,
          mandatorStatus: 1,
          //responseCode: action.payload?.code,
          loading: false,
          mandator: null,
        };
      })
      .addCase(getConfigurationsAsync.pending, (state) => {
        return {
          ...state,
          loading: true,
          responseCode: "idle",
        };
      })
      .addCase(getConfigurationsAsync.fulfilled, (state, action) => {
        return {
          ...state,
          responseCode: "200",
          loading: false,
          configuration: action.payload.data,
        };
      })
      .addCase(getConfigurationsAsync.rejected, (state) => {
        return {
          ...state,
          responseCode: "500",
          loading: false,
          configuration: {},
        };
      });
  },
});

export const {
  setSwitchAccount,
  expirationToken,
  setProfile,
  setBreadcrumb,
  setToasterState,
  setSelectedRepertoire,
  resetSelectedRepertoires,
} = appContextSlice.actions;

export default appContextSlice.reducer;
