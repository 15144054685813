import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { allowedStatus } from "../../../components/topStatusBar";
import FiltersComponent from "../../../components/filtersComponent";
import { Select, SelectItem } from "@mantine/core";
import ApplyButtonComponent from "../../../components/filtersComponent/applyButtonComponent";
import { subMonths } from "date-fns";
import "./index.scss";
import iconReset from "./../../../assets/img/icon-reset-new.svg";
import { FF_ADVANCED_SEARCH } from "../../../providers/configurationProvider";
import { setToasterState, setBreadcrumb } from "../../../slices/appContextSlice";
import {
  PostAdvancedSearchRequestPayload,
  SearchModes,
  SearchModesBiMap,
} from "../../../types/advancedSearchTypes";
import {
  useAdvancedSearchPostMutation,
  useQueryGetExports,
  useQueryLicenses,
} from "../../../react-query-hooks/advancedSearchQueries";
import {
  setSearchParamsDatesAction,
  setSearchParamsDspsAction,
  setSearchParamsTerritoriesAction,
  reseAdvancedSearchSearchParams,
  setSearchParamsTitleAction,
  setSearchParamsTitleModeAction,
  setSearchParamsSacemWorkCodesAction,
  setSearchParamsParticipantModeAction,
  setSearchParamsVideoIdAction,
  setSearchParamsDspResourceCodesAction,
  setSearchParamsIsrcAction,
  setSearchParamsIswcAction,
  setSearchParamsParticipantAction,
} from "../../../slices/advancedSearchSlice";
import { getLastDayOfMonth, getOffsetDate } from "../../../utils/dateUtils";
import {
  ButtonWrapper,
  FilterBar,
  FilterWrapper,
} from "../../../components/filtersComponent/FilterWrappers";
import ShowMoreFilterBtn from "../../../components/filtersComponent/ShowMoreFiltersBtn";
import { useQueryClient } from "@tanstack/react-query";

const AdvancedSearchFilters = ({ mandatorId }) => {
  const dispatch = useAppDispatch();

  const sisterSocietyId = useAppSelector(
    (state) => state.appContext.selectedSisterSociety.sisterSocietyId
  );
  const contractStartDate = useAppSelector((state) => state.appContext.mandatorStartDate);
  const mandatorStatus = useAppSelector((state) => state.appContext.mandatorStatus);
  const searchParams = useAppSelector((state) => state.advancedSearch.searchParams);

  const queryClient = useQueryClient();
  const { status } = useQueryGetExports(sisterSocietyId);
  const licensesQueried = useQueryLicenses(mandatorId);
  const {
    isError: exportRegistrationfailed,
    isLoading: postLoading,
    mutate: registerExportRequest,
  } = useAdvancedSearchPostMutation(queryClient, sisterSocietyId);

  const [territoriesFilter, setTerritoriesFilter] = useState<SelectItem[]>([]);
  const [dspsFilter, setDspsFilter] = useState<SelectItem[]>([]);
  const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);

  useEffect(() => {
    if (licensesQueried.status === 200) {
      setTerritoriesFilter(
        licensesQueried.returnData.territory.map((territory) => {
          return {
            label: territory.label,
            value: territory.label,
            code: territory.territoryId,
          };
        })
      );
      setDspsFilter(
        licensesQueried.returnData.dsp.map((dsp) => {
          return {
            label: dsp.label,
            value: dsp.label,
            code: dsp.label,
          };
        })
      );
    }
  }, [
    licensesQueried.status,
    licensesQueried.returnData.dsp,
    licensesQueried.returnData.territory,
  ]);

  useEffect(() => {
    dispatch(
      setToasterState({
        status: !allowedStatus.includes(licensesQueried.status) || exportRegistrationfailed,
        nature: "error",
        messageTranslationKey: "errorPage.500.message",
      })
    );
    return () => {
      dispatch(
        setToasterState({
          status: false,
        })
      );
    };
  }, [dispatch, licensesQueried.status, exportRegistrationfailed]);

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: t("breadcrumb.advanced-search"),
        showHome: true,
        featKey: FF_ADVANCED_SEARCH,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    // Disable other items from multi select if 3 or more items already selected
    if (searchParams.dsps.length > 2) {
      setDspsFilter((oldDspsFilter) => {
        return oldDspsFilter.map((d) => {
          if (searchParams.dsps.includes(d.value)) {
            return d;
          } else {
            return { ...d, disabled: true };
          }
        });
      });
    } else {
      setDspsFilter((oldDspsFilter) => {
        return oldDspsFilter.map((d) => {
          return {
            label: d.label,
            value: d.value,
            code: d.code,
          };
        });
      });
    }
  }, [searchParams.dsps]);

  useEffect(() => {
    // Disable other items from multi select if 3 or more items already selected
    if (searchParams.territories.length > 2) {
      setTerritoriesFilter((oldTerritoriesFilter) => {
        return oldTerritoriesFilter.map((d) => {
          if (searchParams.territories.some((t) => t.alpha2_label === d.code)) {
            return d;
          } else {
            return { ...d, disabled: true };
          }
        });
      });
    } else {
      setTerritoriesFilter((oldTerritoriesFilter) => {
        return oldTerritoriesFilter.map((d) => {
          return {
            label: d.label,
            value: d.value,
            code: d.code,
          };
        });
      });
    }
  }, [searchParams.territories]);

  //handlers
  const handleResetFilters = () => {
    dispatch(reseAdvancedSearchSearchParams());
  };
  const handleDateChange = (dates: { startingDate: string; endingDate: string }) => {
    dispatch(
      setSearchParamsDatesAction({
        dates: { startingDate: dates.startingDate, endingDate: dates.endingDate },
      })
    );
  };
  const handleTerritoriesChange = (items: SelectItem[]) => {
    dispatch(
      setSearchParamsTerritoriesAction(
        items.map((item) => {
          return { display_label: item.value, alpha2_label: item.code };
        })
      )
    );
  };
  const handleDspsChange = (selectedItems: SelectItem[]) => {
    dispatch(setSearchParamsDspsAction(selectedItems.map((item) => item.value)));
  };
  const handleTitleChange = (title: string) => {
    dispatch(setSearchParamsTitleAction(title));
  };
  const handleVideoIdChange = (videoId: string) => {
    dispatch(setSearchParamsVideoIdAction(videoId));
  };
  const handleIsrcChange = (videoId: string) => {
    dispatch(setSearchParamsIsrcAction(videoId));
  };
  const handleIswcChange = (videoId: string) => {
    dispatch(setSearchParamsIswcAction(videoId));
  };
  const handleParticipantChange = (videoId: string) => {
    dispatch(setSearchParamsParticipantAction(videoId));
  };
  const handleTitleModeChange = (title: SearchModes) => {
    dispatch(setSearchParamsTitleModeAction(title));
  };
  const handleParticipantModeChange = (title: SearchModes) => {
    dispatch(setSearchParamsParticipantModeAction(title));
  };
  const handleSacemWorkCodeChange = (codes: string[]) => {
    dispatch(setSearchParamsSacemWorkCodesAction(codes));
  };
  const handleDspResourceCodesChange = (codes: string[]) => {
    dispatch(setSearchParamsDspResourceCodesAction(codes));
  };

  const getMinDate = (): Date => {
    const minDate = subMonths(new Date(), 36);
    if (!contractStartDate) return minDate;
    const contractStartDateObject = new Date(contractStartDate);
    return contractStartDateObject < minDate ? minDate : contractStartDateObject;
  };

  const globalStatus = [licensesQueried.status];
  const handleRegisterExport = () => {
    const formattedStartDate = `${searchParams.dates.startingDate.substring(
      0,
      4
    )}-${searchParams.dates.startingDate.substring(4)}-01`;

    const endDateYear = searchParams.dates.endingDate.substring(0, 4);
    const endDateMonth = searchParams.dates.endingDate.substring(4);

    const formattedEndDate = `${endDateYear}-${endDateMonth}-${getLastDayOfMonth(
      Number(endDateYear),
      Number(endDateMonth)
    )}`;
    const postRequestPayload: PostAdvancedSearchRequestPayload = {
      societyId: sisterSocietyId,
      start_period: formattedStartDate,
      end_period: formattedEndDate,
    };
    if (searchParams.title) {
      postRequestPayload.title = searchParams.title;
      postRequestPayload.title_filter = SearchModesBiMap.getValue(searchParams.titleMode);
    }
    if (searchParams.dsps.length > 0) {
      postRequestPayload.dsps = searchParams.dsps;
    }
    if (searchParams.territories.length > 0) {
      postRequestPayload.territories = searchParams.territories;
    }
    if (searchParams.isrcCode.length > 0) {
      postRequestPayload.isrc = searchParams.isrcCode;
    }
    if (searchParams.iswcCode.length > 0) {
      postRequestPayload.iswc = "T" + searchParams.iswcCode;
    }

    if (searchParams.sacemWorkCodes.length > 0) {
      postRequestPayload.sacem_work_codes = searchParams.sacemWorkCodes;
    }
    if (searchParams.dspResourceCodes.length > 0) {
      postRequestPayload.dsp_resource_codes = searchParams.dspResourceCodes;
    }
    if (searchParams.videoId) {
      postRequestPayload.video_id = searchParams.videoId;
    }
    if (searchParams.participant) {
      postRequestPayload.participant = searchParams.participant;
      postRequestPayload.participant_filter = SearchModesBiMap.getValue(
        searchParams.participantMode
      );
    }

    registerExportRequest(postRequestPayload);
  };

  const disableSearch = () => {
    return (
      (!searchParams.title &&
        !searchParams.participant &&
        searchParams.isrcCode.length === 0 &&
        searchParams.iswcCode.length === 0 &&
        searchParams.dspResourceCodes.length === 0 &&
        searchParams.sacemWorkCodes.length === 0 &&
        searchParams.videoId.length === 0) ||
      searchParams.dsps.length === 0 ||
      searchParams.territories.length === 0
    );
  };
  return (
    <>
      <FilterBar>
        <div style={{ display: "flex", width: "60%", minWidth: "60%" }}>
          <FilterWrapper>
            <FiltersComponent
              disabled={mandatorStatus !== 2}
              textPlaceholder={`${t("advanced-search.filters.period.header")}`}
              handleComponentChange={handleDateChange}
              startDate={searchParams.dates.startingDate}
              endDate={searchParams.dates.endingDate}
              maxDate={getOffsetDate(0, 1, 0)}
              minDate={getMinDate()}
              filterType={"monthDateRange"}
              widthFilter={"100%"}
            />
          </FilterWrapper>
          <FilterWrapper>
            <FiltersComponent
              statusArray={globalStatus}
              textPlaceholder={`${t("advanced-search.filters.territories-placeholder")}`}
              data={territoriesFilter}
              filterType={"multiSelect"}
              handleComponentChange={handleTerritoriesChange}
              status={licensesQueried.status}
              selectedValues={searchParams.territories.map((terr) => {
                return terr.alpha2_label;
              })}
              widthFilter={"100%"}
              disabled={![200, 204].includes(licensesQueried.status) || mandatorStatus !== 2}
            />
          </FilterWrapper>
          <FilterWrapper>
            <FiltersComponent
              statusArray={globalStatus}
              textPlaceholder={`${t("advanced-search.filters.providers-placeholder")}`}
              data={dspsFilter}
              filterType={"multiSelect"}
              handleComponentChange={handleDspsChange}
              status={licensesQueried.status}
              selectedValues={searchParams.dsps}
              widthFilter={"100%"}
              disabled={![200, 204].includes(licensesQueried.status) || mandatorStatus !== 2}
            />
          </FilterWrapper>
        </div>
        <div
          style={{
            display: "flex",
            width: "40%",
            minWidth: "40%",
          }}
        >
          <Select
            style={{ marginInline: "10px", maxWidth: "103px" }}
            data={SearchModesBiMap.getAllKeys().map((label) => ({
              label: label,
              code: label,
              value: label,
            }))}
            value={searchParams.titleMode}
            onChange={handleTitleModeChange}
            rightSection={<span />}
            styles={() => ({
              root: {
                flex: "0 0 120px",
              },
              rightSection: { pointerEvents: "none" },
              input: {
                height: "38px",
                alignContent: "center",
                border: "none",
                backgroundColor: "#e4e7eb",
              },
            })}
            rightSectionWidth={30}
          />
          <FiltersComponent
            textPlaceholder={`${t("advanced-search.filters.title-placeholder")}`}
            filterType={"inputText"}
            handleComponentChange={handleTitleChange}
            selectedValue={searchParams.title}
            widthFilter={"94%"}
          />
        </div>
      </FilterBar>
      <FilterBar style={{ paddingTop: 0 }}>
        <div
          style={{
            width: "60%",
            display: "flex",
            minWidth: "60%",
          }}
        >
          <FilterWrapper>
            <FiltersComponent
              textPlaceholder={`${t("advanced-search.filters.isrc-placeholder")}`}
              filterType={"inputText"}
              handleComponentChange={handleIsrcChange}
              selectedValue={searchParams.isrcCode}
              widthFilter={"94%"}
            />
          </FilterWrapper>
          <FilterWrapper>
            <FiltersComponent
              textPlaceholder={`${t("advanced-search.filters.iswc-placeholder")}`}
              filterType={"inputText"}
              handleComponentChange={handleIswcChange}
              selectedValue={searchParams.iswcCode}
              widthFilter={"94%"}
              prefix="T"
              isNumeric
            />
          </FilterWrapper>
          <FilterWrapper>
            <FiltersComponent
              isNumeric={true}
              textPlaceholder={`${t("advanced-search.filters.sacem-work-code-placeholder")}`}
              filterType={"creatableMultiSelect"}
              handleComponentChange={handleSacemWorkCodeChange}
              data={searchParams.sacemWorkCodes.map((code) => {
                return { value: code, label: code };
              })}
              selectedValues={searchParams.sacemWorkCodes}
              widthFilter={"94%"}
            />
          </FilterWrapper>
        </div>
        <div
          style={{
            display: "flex",
            width: "40%",
            minWidth: "40%",
            alignItems: "center",
          }}
        >
          <FilterWrapper>
            <Select
              style={{ marginInline: "10px", maxWidth: "103px" }}
              data={SearchModesBiMap.getAllKeys().map((label) => ({
                label: label,
                code: label,
                value: label,
              }))}
              value={searchParams.participantMode}
              onChange={handleParticipantModeChange}
              rightSection={<span />}
              styles={() => ({
                root: {
                  flex: "0 0 120px",
                },
                rightSection: { pointerEvents: "none" },
                input: {
                  height: "38px",
                  alignContent: "center",
                  border: "none",
                  backgroundColor: "#e4e7eb",
                },
              })}
              rightSectionWidth={30}
            />
            <FiltersComponent
              textPlaceholder={`${t("advanced-search.filters.participant-placeholder")}`}
              filterType={"inputText"}
              handleComponentChange={handleParticipantChange}
              selectedValue={searchParams.participant}
              widthFilter={"94%"}
            />
          </FilterWrapper>
          {!showMoreFilters && (
            <>
              <ButtonWrapper>
                <ShowMoreFilterBtn
                  onClickHandler={() => setShowMoreFilters(true)}
                  text={t("advanced-search.filters.more-filters")}
                  cssArrowDirection={"arrow-down"}
                />
              </ButtonWrapper>
              <ButtonWrapper>
                <button className="advanced-search-reset-btn" onClick={handleResetFilters}>
                  {iconReset && <img src={iconReset} alt="reset" />}
                </button>
              </ButtonWrapper>
              <ButtonWrapper>
                <ApplyButtonComponent
                  onClickHandler={() => {
                    handleRegisterExport();
                  }}
                  disabled={
                    licensesQueried.status === -1 ||
                    disableSearch() ||
                    postLoading ||
                    ![200, 204].includes(status)
                  }
                  tagLabel="paths-tag-labels.advanced-search-title"
                  buttonText="Export"
                  hasHover={disableSearch()}
                  hoverText="Select up to one year, at least one DSP and territory (up to three) and fill at least one other criteria to generate an export"
                />
              </ButtonWrapper>{" "}
            </>
          )}
        </div>
      </FilterBar>
      {showMoreFilters && (
        <FilterBar style={{ paddingTop: 0 }}>
          <div
            style={{
              width: "40%",
              display: "flex",
              minWidth: "40%",
            }}
          >
            <FilterWrapper>
              <FiltersComponent
                textPlaceholder={`${t("advanced-search.filters.dsp-ressource-code-placeholder")}`}
                filterType={"creatableMultiSelect"}
                handleComponentChange={handleDspResourceCodesChange}
                data={searchParams.dspResourceCodes.map((code) => {
                  return { value: code, label: code };
                })}
                selectedValues={searchParams.dspResourceCodes}
                widthFilter={"50%"}
                isNumeric
              />
            </FilterWrapper>
            <FilterWrapper>
              <FiltersComponent
                textPlaceholder={`${t("advanced-search.filters.video-id-placeholder")}`}
                filterType={"inputText"}
                handleComponentChange={handleVideoIdChange}
                selectedValue={searchParams.videoId}
                widthFilter={"94%"}
                isNumeric={true}
              />
            </FilterWrapper>
          </div>
          <div
            style={{
              width: "40%",
              display: "flex",
              minWidth: "60%",
              justifyContent: "end",
              alignContent: "center",
            }}
          >
            <ButtonWrapper>
              <ShowMoreFilterBtn
                onClickHandler={() => setShowMoreFilters(false)}
                text={"Less filters"}
                cssArrowDirection={"arrow-up"}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <button className="advanced-search-reset-btn" onClick={handleResetFilters}>
                {iconReset && <img src={iconReset} alt="reset" />}
              </button>
            </ButtonWrapper>
            <ButtonWrapper>
              <ApplyButtonComponent
                onClickHandler={() => {
                  handleRegisterExport();
                }}
                disabled={
                  licensesQueried.status === -1 ||
                  disableSearch() ||
                  postLoading ||
                  ![200, 204].includes(status)
                }
                tagLabel="paths-tag-labels.advanced-search-title"
                buttonText="Export"
                hasHover={disableSearch()}
                hoverText="Select up to one year, at least one DSP and territory (up to three) and fill at least one other criteria to generate an export"
              />
            </ButtonWrapper>
          </div>
        </FilterBar>
      )}
    </>
  );
};
export default AdvancedSearchFilters;
