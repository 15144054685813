import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { t } from "i18next";
import { setBreadcrumb } from "../../slices/appContextSlice";
import { FF_ADVANCED_SEARCH } from "../../providers/configurationProvider";
import { Container, Row } from "react-bootstrap";
import AdvancedSearchFilters from "./filters";
import "./index.scss";
import { useQueryGetExports } from "../../react-query-hooks/advancedSearchQueries";
import { Separator } from "../kpis/licenseKpi";
import ExportsTable from "./ExportsTable";
import ErrorPage from "../../components/error";
const AdvancedSearch = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: t("breadcrumb.advanced-search"),
        showHome: true,
        featKey: FF_ADVANCED_SEARCH,
      })
    );
  }, []);
  const sisterSocietyId = useAppSelector(
    (state) => state.appContext.selectedSisterSociety.sisterSocietyId
  );
  const mandatorId = useAppSelector((state) => state.appContext.mandator);
  const mandatorStatus = useAppSelector((state) => state.appContext.mandatorStatus);
  const exportsList = useQueryGetExports(sisterSocietyId);

  const constructTableData = () => {
    if (exportsList?.data?.data && exportsList.data.data !== null)
      return [...exportsList.data.data];

    if (exportsList?.data?.data && exportsList.data.data !== null) {
      return exportsList.data.data;
    }

    return [];
  };
  if (mandatorId === null || mandatorStatus === 0) {
    return <Container />;
  }
  return (
    <Container fluid className="advanced-search-page-container">
      <AdvancedSearchFilters mandatorId={mandatorId} />
      {[-1, 200, 204].includes(exportsList.status) ? (
        <Container fluid style={{ marginBottom: "15px" }}>
          <Row>{Separator(t("advanced-search.table.title"), "15px")}</Row>

          <ExportsTable exportsList={constructTableData()} isLoading={exportsList.status === -1} />
        </Container>
      ) : (
        <ErrorPage errorCode={500} />
      )}
    </Container>
  );
};

export default AdvancedSearch;
