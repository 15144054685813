import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import './index.scss';
import {Profile} from 'oidc-client/dist/oidc-client';
import {AppPropsType, SisterSociety} from '../../types';
import { connect, useDispatch } from 'react-redux';
import UrightsBox from '../../components/urightsBox';
import { Link } from 'react-router-dom';
import OverlayCustom from '../../components/overlay';
import { setBreadcrumb } from '../../slices/appContextSlice';
import { ROUTES, ROLES } from '../../constants';
import {
	ConfigurationContext,
	FF_DISTRIBUTION_ROYALTIES,
	FF_USAGE_DATA,
	isEnabledService,
	FF_MARKET_SHARE,
} from '../../providers/configurationProvider'

const LandingPage = (props: Props) => {
	const {profile}=props
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setBreadcrumb({
			isVisible: false
		}));
	}, []);

	const hasWebOnlineRole = () => {
		const listRoles: string[] | undefined = profile?.roles;
		return listRoles && listRoles.includes(ROLES.WEBSO_WEB_ONLINE);
	};

	const config = useContext(ConfigurationContext);
	const isEnabledUsageData = isEnabledService(config, FF_USAGE_DATA)
	const isEnabledDistributionRoyalties = isEnabledService(config, FF_DISTRIBUTION_ROYALTIES)
	const isEnabledMarketShare = isEnabledService(config, FF_MARKET_SHARE)

	return (

		<div className='bg-landing'>
			<Container>

				{hasWebOnlineRole() ? (
				<>
					<div className="d-flex">
						{isEnabledUsageData &&
							<UrightsBox
								className="usage-data first-box"
								external={false}
								buttonLabel={t('page.title.usage-data')}
								redirectionLink={ROUTES.USAGE_DATA}
							/>
						}
						{isEnabledMarketShare &&
							<UrightsBox
								className="kpi"
								external={false}
								buttonLabel={t('page.title.kpi-online')}
								redirectionLink={ROUTES.KPI}
							/>
						}
						{isEnabledDistributionRoyalties &&
							<UrightsBox
								className="distribution-royalties last-box"
								external={false}
								buttonLabel={t('page.title.distribution-royalties')}
								redirectionLink={ROUTES.DISTRIBUTION_ROYALTIES}
							/>
						}
					</div>
				</>) :
				(<>
					<div className="first-block">
						<div className="card-wrapper setlists">
							<div className="header">
								<div className="title">
									{t('landing-page.my-setlist')}
								</div>
							</div>
							<div className="card-content">
								<Link to={{ pathname: ROUTES.CREATE_SETLIST }} className="link create-setlist">
									{t('landing-page.create-set-list')}
								</Link>

								<Link to={{ pathname: ROUTES.GET_SETLISTS }} className="link check-setlists">
									{t('landing-page.check-set-list')}
								</Link>
							</div>
						</div>

						<div className="card-wrapper events">
							<div className="header">
								<div className="title">
									{t('landing-page.my-events')}
								</div>
							</div>
							<div className="card-content">
								<Link to={{ pathname: '/events' }} className="link check-events">
									{t('landing-page.check-events')}
								</Link>

								<OverlayCustom
									content={t('landing-page.tooltip')}
									placement="top"
								>
									<div className="info"/>
								</OverlayCustom>
							</div>
						</div>
					</div>
				</>)
				}

				<div className="last-block">
					<a href={process.env.REACT_APP_DISTRIBUTION_RULES_URL} target="_blank" rel="noopener noreferrer">
						{t('landing-page.footer.link1')}
					</a>
					<div className="line" />
					<a href={process.env.REACT_APP_ANNUAL_REPORTS_URL} target="_blank" rel="noopener noreferrer">
						{t('landing-page.footer.link2')}
					</a>
					<div className="line" />
					<a href={process.env.REACT_APP_ARTICLE_REGULATIONS_URL} target="_blank" rel="noopener noreferrer">
						{t('landing-page.footer.link3')}
					</a>
				</div>
			</Container>




		</div>


	);
};
const mapStateToProps = (state: AppPropsType) => ({
	profile: state.appContext.profile,
	selectedSisterSocitieId: state.appContext.selectedSisterSociety
});

export default connect(mapStateToProps)(LandingPage);

interface Props{
	selectedSisterSocitieId: SisterSociety|null
	profile: Profile | null,
}
